// If you want to override variables do it here
@import "variables";
@import 'sweetalert2/src/sweetalert2.scss';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";


.table-responsive {
  overflow-x: auto !important;
}

.btn-primary {
  background: #1E4E76 !important;
}

.sidebar {
  background: #1E4E76 !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.dataNot_found {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
}

.swal2-title {
  font-size: 16px !important;
  text-align: left !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #2222 !important;
}

.swal2-actions {
  justify-content: right !important;
  margin: 0 !important;
  padding: 0;
}

.swal2-styled.swal2-confirm {
  padding: 5px 20px !important;
  margin-top: 20px;
}

.swal2-styled.swal2-deny {
  padding: 5px 20px !important;
  margin-top: 20px;
  margin-right: 25px;
}

.card__body__area td {
  padding: 5px !important;
}
.card__body__area tbody button {
	padding: 4px !important;
	line-height: 0px !important;
}
.card__body__area .ant-pagination-options {
  display: none !important;
}

.card__body__area .table thead tr th, .card__body__area .table tbody tr td {
  border: 1px solid #2222;
  text-align: left;
  padding-left: 10px;
}

.ant-picker:hover, .ant-picker-focused {
	border-color: #d8dbe0 !important; 
	border-inline-end-width: 0px !important; 
}

.ant-picker .ant-picker-input > input::placeholder {
	color: #727272 !important;
	-webkit-user-select: none;
	user-select: none;
	font-size: 16px;
	font-weight: 500;
}


.ant-select-selection-placeholder {
  color: #727272 !important;
	-webkit-user-select: none;
	user-select: none;
	font-size: 16px;
	font-weight: 500;
}

.table  {
	font-size: 14px !important;
  font-weight: 500;
}

.ant-select-selector {
  height: 39px !important;
  padding: 3px 11px !important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
	border-color: #b1b7c1 !important;
}